import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader';
import ContactUsForm from '../components/ContactUsForm'

export const ContactUsPageTemplate = ({
  image,
  heading,
  description,
}) => (
  <div className="content">
    <PageHeader heading={heading} description={description} image={image} />
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-12 contact-us">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </section>
  </div>
)

ContactUsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  description: PropTypes.string,
}

const ContactUsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  console.log(frontmatter.categories);

  return (
    <Layout>
      <ContactUsPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        description={frontmatter.description}
      />
    </Layout>
  )
}

ContactUsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactUsPage

export const contactUsPageQuery = graphql`
  query ContactUsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
